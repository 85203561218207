import Router from './routes'
import { store, ThriveApplication } from '@thriveglobal/thrive-web-core'
import { Provider } from 'react-redux'
import pulseSlice from './slices'
import apolloCacheOptions from './graphql/cacheConfig/apolloCacheOptions'
import PulseAppContexts from './context/PulseAppContexts'

const loadi18n = (lang: string) =>
  fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
    .then((res) => res.json())
    .catch(console.warn)
export default function Root() {
  // Adding new dynamic slice to redux store for pulse view
  store.injectSlice({ pulse: pulseSlice })
  return (
    <ThriveApplication
      messagesLoaders={[loadi18n]}
      configCatSdk={process.env.CONFIG_CAT}
      commitHash={process.env.GIT_REF}
      apolloClientOptions={{
        name: 'thrive-web-pulse',
        cacheOptions: apolloCacheOptions,
      }}
      disableMaxWidth
    >
      <Provider store={store}>
        <section>
          <PulseAppContexts>
            <Router />
          </PulseAppContexts>
        </section>
      </Provider>
    </ThriveApplication>
  )
}

// Global __THRIVE__ object
declare global {
  interface Window {
    __THRIVE__: any
  }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
  app: process.env.APP_NAME,
  version: process.env.APP_VERSION,
  created: process.env.CREATED_AT,
})
